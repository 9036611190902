import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, isDevMode, ViewChild } from '@angular/core';
import { MaterialModule } from 'app/modules/material/material.module';
import { SharedModule } from 'app/modules/shared/shared.module';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, LogService, PopupService, UtilityService } from 'app/services';
import { RecaptchaComponent, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LOG_TYPE } from 'app/constants';
import { environment } from 'environments/environment';
import { LoginOrRegisterFrame } from '../login-or-register-frame/login-or-register-frame.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { InputRequiredErrorComponent } from 'app/standalone-components/input-required-error/input-required-error.component';
import { Subscription } from 'rxjs';
import { PackagesFlowService } from 'app/services/packages-flow.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialModule, RecaptchaModule, FormsModule, RecaptchaFormsModule, ReactiveFormsModule, LoginOrRegisterFrame, InputRequiredErrorComponent],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class LoginDialogComponent {

  @Input() isCloseButtonVisible: boolean = true;

  @ViewChild("recaptchaComponent") reCaptcha: RecaptchaComponent;

  submitForm: FormGroup = new FormGroup({});
  showRecaptchaMessage: boolean = false;
  emailFormatInvalid: boolean;
  currentEmailCheck: Subscription;
  hide: boolean = true;
  showError: boolean = false;
  isDevMode = isDevMode();

  isRecaptchaDisabled: boolean;
  isCredentialsError: boolean;
  siteKey: string = environment.google.InvisibleCaptchaKey;
  captcha_value: string;

  onUserLoginComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  //TODO seaprate and create a dialog which uses the login comp
  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    public popupService: PopupService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private logService: LogService,
    private formBuilder: FormBuilder,
    private packagesFlowService: PackagesFlowService
  ) {
    this.submitForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      captcha: ['']
    })
  }

  validateEmailInput(): void {
    if (!this.submitForm.controls.email.errors?.email) {
      this.emailFormatInvalid = false;
      if (this.submitForm.controls.email.value) {
        this.currentEmailCheck = this.authService.validateEmailAddress(this.submitForm.value.email, this.captcha_value).subscribe({
          error: (error) => {
          }
        });
      }
    } else {
      this.emailFormatInvalid = true;
    }
  }

  reCaptchaResolved(captchaResponse: string) {
    if (captchaResponse) {
      this.captcha_value = captchaResponse;
      this.logService.log(LOG_TYPE.INFO, `Resolved captcha with response:`, captchaResponse);
      this.showRecaptchaMessage = false;
      this.submitLoginData();
    }
  }

  googleLogin() {
    this.authService.loginWithGoogle()
  }

  reCaptchaError(captchaResponse) {
    this.showRecaptchaMessage = true;
    this.logService.log(LOG_TYPE.WARNING, `Resolved captcha with error:`, JSON.stringify(captchaResponse));
  }

  submitLoginData() {
    if (!this.submitForm.value.email || !this.submitForm.value.password) {
      this.showError = true;
      return;
    } else {
      let form = this.submitForm.value;
      this.submitForm.value.captcha = this.captcha_value;
      this.authService.login(form.email, form.password, form.captcha).subscribe({
        next: () => {
          this.utilityService.trackEvent('Login', 'Response', 'Success');
          this.utilityService.trackEvent('login', 'email');
          const subs = this.authService.onCurrentUserChange.subscribe(() => {
            this.onUserLoginComplete.emit(true);
            subs.unsubscribe();
            this.dialogRef.close();
            this.packagesFlowService.getUserPackages(this.authService.user.id).subscribe()
          })
        },
        error: () => {
          this.isCredentialsError = true;
          this.reCaptcha.reset();
        }
      });
    }
  }

  onSubmit(): void {
    if (this.submitForm.valid) {
      this.isRecaptchaDisabled = environment?.production !== true;

      if (!this.isRecaptchaDisabled) {
        this.reCaptcha.execute();
      } else {
        this.captcha_value = "testCaptchaResponse";
        this.submitLoginData();
      }
    }
  }

  openRegister() {
    this.popupService.showRegisterModalStudent();
    this.dialogRef.close();
  }

  redirectAndClose() {
    this.popupService.showRegisterModalTutor();
    this.dialogRef.close();
  }
}
