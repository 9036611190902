import { CheckboxComponent } from './../../checkbox/checkbox.component';
import { FaqListComponent } from './../../faq-list/faq-list.component';
import { UtilityService } from "../../../services/utility.service";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "app/modules/shared/shared.module";
import { PackagesFlowService } from "app/services/packages-flow.service";
import {
  ExamSubscriptionRequestModel,
  InstallmentOptionEnum,
  PackageDetails,
  PackageRecommendationModel,
  PackageRecommendationRequestModel,
  PackageSubscriptionRequestModel,
  SelectedPackageData,
} from "../../../models/package.model";
import { SequenceOptions, TutorSeniorityLevels } from "app/constants/packages";
import { MatDialog } from "@angular/material/dialog";
import {
  AuthService,
  BookingService,
  PopupService,
  ToastService,
} from "app/services";
import { MessageType } from "app/constants";
import { TranslateService } from "@ngx-translate/core";
import { Observable, from, map, mergeMap, toArray } from "rxjs";
import { SubscribedDialogComponent } from "../subscribed/subscribed-dialog.component";
import { ConfirmDialogComponent } from "app/standalone-components/common-dialogs/confirm/confirm-dialog.component";
import { PaymentMethod, StripePaymentIntent } from 'app/models/payment-intent.model';
import { PaymentInfoForForRedirect } from 'app/models/payment-info-for-redirect.model';
import { BookingDataModel, RecurrentBookingDataModel } from 'app/models';

@Component({
  selector: "app-package-cards",
  standalone: true,
  imports: [CommonModule, SharedModule, ConfirmDialogComponent, FaqListComponent, CheckboxComponent],
  templateUrl: "./package-cards.component.html",
  styleUrls: ["./package-cards.component.scss"],
})
export class PackageCardsComponent implements OnInit {
  @Input() selectedTutorLevel: number;
  @Input() selectedLessonPerWeek;
  @Input() isModificationsAllowed: boolean = false;
  @Input() showAlternativePackages: boolean = false;
  @Input() showExamPackages: boolean = false;
  @Input() bookingDetailsAfterPayment?: BookingDataModel;
  @Input() bookingRecurrentDetailsAfterPayment?: RecurrentBookingDataModel;

  @Input() selectedPackageData?: SelectedPackageData;

  @Output() onClose = new EventEmitter();
  @Output() onNewPackagePurchase = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  @ViewChild("title", { read: ElementRef }) title: ElementRef;

  packageCards: Array<PackageDetails> = [];
  packageSelectedToBuy: PackageDetails;

  tutorTypes = TutorSeniorityLevels;
  lessonsPerWeek = SequenceOptions;
  lessonPerMonth;
  isPaymentMethodOpened: boolean = false;
  paymentMethod: number;

  get PaymentMethod(): typeof PaymentMethod {
    return PaymentMethod;
  }

  faqTitle: string;
  faqListItems;

  isExamMode: boolean = false;
  isWarning: boolean = false;
  isEntryPackageAllowed = false;

  constructor(
    public utilityService: UtilityService,
    public dialog: MatDialog,
    private authService: AuthService,
    private toastService: ToastService,
    private packagesFlowService: PackagesFlowService,
    private translationService: TranslateService,
    private popupService: PopupService,
    private bookingService: BookingService,
  ) { }

  ngOnInit() {
    this.utilityService.pushEventVWO("packageDialogueSecondPage")
    if (this.selectedPackageData) {
      this.initAlreadySelectedWarning();
    } else {
      this.initPackages();
    }
    this.translateFAQ();
    this.utilityService.trackEvent("package", "packageShoved");
  }

  ngAfterViewInit() {
    if (this.utilityService.isBrowser && !this.isModificationsAllowed)
      setTimeout(() => {
        this.title.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 50);
  }

  previousStep() {
    this.isPaymentMethodOpened = false;
    this.isWarning = false;
  }

  initPackages() {
    if (
      this.showExamPackages
    ) {
      this.isExamMode = true;
      this.getExamPackages().subscribe((packages) => {
        this.packageCards = packages;
      });
    } else {
      let packageCards = [];
      this.getPackages().subscribe((packages) => {
        packageCards = packages.sort(
          (a, b) => parseInt(b.durationMonths) - parseInt(a.durationMonths)
        );
        this.checkToAddEntryPackage(packageCards)
      });
    }
  }

  checkToAddEntryPackage(packageCards) {
    if (this.utilityService.isDE) {
      this.packagesFlowService.getUserPackages(+this.authService.userDetails.id).subscribe((userPackages) => {
        if (userPackages.packages.length > 0) {
          const hasPackageWithStartDate = userPackages.packages.some(userPackage =>
            userPackage.userPackage.startDate
          );

          if (hasPackageWithStartDate) {
            this.packageCards = packageCards;
          } else {
            this.addEntryPackage(packageCards);
          }
        } else {
          this.addEntryPackage(packageCards);
        }
      });
    } else {
      this.packageCards = packageCards;
    }
  }

  addEntryPackage(packageCards) {
    packageCards = packageCards.filter(packageCard => packageCard.durationMonths !== "14");
    this.getExamPackages().subscribe((packages) => {
      this.isEntryPackageAllowed = true;
      const entryPackage = packages.filter(
        (examPackage) => examPackage._model.totalLessons === "2"
      );
      entryPackage[0]._model.isEntryPackage = true;
      packageCards.push(entryPackage[0]);
      this.addBadgeNames(packageCards)
      this.packageCards = packageCards;
    });
  }

  addBadgeNames(packageCards) {
    packageCards[2] = { ...packageCards[2], _badgeName: "texts.Package_Cards_Component_Entry_Package_Badge_Name" }
    packageCards[1] = { ...packageCards[1], _badgeName: "texts.Package_Cards_Component_Standard_Package_Badge_Name" }
    packageCards[0] = { ...packageCards[0], _badgeName: "texts.Package_Cards_Component_Long_Package_Badge_Name" }
  }

  tryAgain() {
    this.bookingService
      .getPackagePaymentIntentRedirectUrl(
        this.selectedPackageData.userPackage.userPackageId
      )
      .subscribe({
        next: (response) => {
          this.saveInfoForRedirect(this.selectedPackageData.userPackage, this.selectedPackageData.paymentIntent);
          this.goToStripe(response.url);
        },
      });
  }

  private initAlreadySelectedWarning() {
    this.packageCards = this.selectedPackageData.packageCards;
    this.packageSelectedToBuy = {
      packageId: this.selectedPackageData.userPackage.packageId,
      durationMonths: this.selectedPackageData.userPackage.durationMonths,
      lessonsPerWeek: this.selectedPackageData.userPackage.lessonsPerWeek ?
        this.selectedPackageData.userPackage.lessonsPerWeek.toString() : "",
      tutorCategory: this.selectedPackageData.userPackage.tutorCategory,
      installmentOption: this.selectedPackageData.installmentOption,
    };
    this.selectedTutorLevel =
      +this.selectedPackageData.userPackage.tutorCategory;
    this.paymentMethod = this.selectedPackageData.paymentMethod;
    this.packageSelectedToBuy = this.selectedPackageData.packageCards.find(
      (x) => x.packageId === this.packageSelectedToBuy.packageId
    );
    if (this.selectedPackageData.userPackage.durationMonths !== "1") {
      this.isPaymentMethodOpened = true;
      this.isWarning = true;
    }
  }

  private getExamPackages(): Observable<PackageDetails[]> {
    return this.packagesFlowService
      .getExamPackages(this.selectedTutorLevel.toString())
      .pipe(
        map((resp) => {
          return resp.offers.map((offer) => {
            return {
              durationMonths: offer.durationMonths.toString(),
              costPerHour: offer.lessonPrice.toString(),
              savePerMonth: offer.savingsPerMonth?.toString(),
              packageId: offer.packageId,
              pricePerMonth: offer.pricePerMonth,
              examPackage: offer.examPackage,
              lessonsPerWeek: offer.lessonsPerWeek,
              _model: offer,
              _isExamPackageVersion: true,
            };
          });
        })
      );
  }

  private getPackages(): Observable<PackageDetails[]> {
    let packageCards: Array<PackageDetails> = [];
    if (this.showAlternativePackages) {
      packageCards = [
        {
          durationMonths: "24",
        },
        {
          durationMonths: "20",
        },
        {
          durationMonths: "16",
        },
        {
          durationMonths: "14",
        },
        {
          durationMonths: "12",
        },
        {
          durationMonths: "10",
        },
        {
          durationMonths: "8",
        },
        {
          durationMonths: "6",
        },
        {
          durationMonths: "5",
        },
        {
          durationMonths: "4",
        },
      ];
    } else {
      packageCards = [
        {
          durationMonths: "9",
        },
        {
          durationMonths: "14",
        },
        {
          durationMonths: "3",
        },
      ];
    }

    // TODO remove this when BE has lessonPerMonth property
    this.lessonPerMonth = +this.selectedLessonPerWeek * 4;

    return from(packageCards).pipe(
      mergeMap((card) => {
        const packageRecommendation: PackageRecommendationRequestModel = {
          durationMonths: card.durationMonths,
          lessonsPerWeek: this.selectedLessonPerWeek,
          tutorCategory: this.selectedTutorLevel.toString(),
          country: this.utilityService.country,
          improvementPurpose: "",
        };
        return this.packagesFlowService
          .getPackagesRecommendation(packageRecommendation)
          .pipe(
            map((resp) => {
              card.costPerHour = resp.lessonPrice.toString();
              card.savePerMonth = resp.savingsPerMonth
                ? resp.savingsPerMonth.toString()
                : "";
              card.packageId = resp.packageId;
              card.pricePerMonth = resp.pricePerMonth;
              card.lessonsPerWeek = resp.lessonsPerWeek;
              card.totalInstallment = resp.totalInstallment;
              card.pricePerInstallment = resp.pricePerInstallment;
              card._totalPrice = resp.price;
              card._totalLessonsInHours = resp.totalLessons;
              return card;
            })
          );
      }),
      toArray()
    );
  }

  openPaymentMethod() {
    if (this.authService.isTeacher()) {
      this.toastService.showMsg(
        "texts.Packages_Section_Tutor_Buy",
        MessageType.info,
        { title: "texts.Packages_Section_Tutor_Buy_title" }
      );
      return;
    }
    this.utilityService.pushEventVWO("packageDialogueThirdPage")
    this.isPaymentMethodOpened = true;
    this.nextStep.emit();
  }

  togglePaymentMethod(paymentMethod: number) {
    this.paymentMethod = paymentMethod === this.paymentMethod ? undefined : paymentMethod;
    this.isWarning = false;
  }

  openOrConfirmSpecificPackage() {
    if (this.paymentMethod == PaymentMethod.Subsidy) {
      this.openPaymentTypeDialog();
    } else {
      this.confirmPackage();
    }
  }

  openPaymentTypeDialog() {
    if (this.packageSelectedToBuy.examPackage || this.showExamPackages || this.packageSelectedToBuy._model.isEntryPackage) {
      this.confirmPackage();
    } else {
      let dialogComp = this.popupService.openResponsiveDialog(ConfirmDialogComponent).componentInstance;
      dialogComp.dialogTitle = "texts.Payment_Subsidy_Confirmation_Title";
      dialogComp.dialogText = "texts.Payment_Subsidy_Confirmation_Description";
      dialogComp.onConfirm.subscribe(() => {
        this.onClose.emit();
        this.packageSelectedToBuy.installmentOption = InstallmentOptionEnum.single;
        this.confirmPackage();
      })
    }
  }

  private translateFAQ() {
    this.translationService
      .get([
        "texts.Price_Page_faq_list_title",
        "texts.Package_Tool_FAQ_Item1_Title",
        "texts.Package_Tool_FAQ_Item1",
        "texts.Package_Tool_FAQ_Item2_Title",
        "texts.Package_Tool_FAQ_Item2",
        "texts.Package_Tool_FAQ_Item3_Title",
        "texts.Package_Tool_FAQ_Item3",
        "texts.Package_Tool_FAQ_Item4_Title",
        "texts.Package_Tool_FAQ_Item4",
        "texts.Package_Tool_FAQ_Item5_Title",
        "texts.Package_Tool_FAQ_Item5",
        "texts.Package_Tool_FAQ_Item6_Title",
        "texts.Package_Tool_FAQ_Item6",
        "texts.Package_Tool_FAQ_Item7_Title",
        "texts.Package_Tool_FAQ_Item7",
      ])
      .subscribe((translations) => {
        this.faqTitle = translations["texts.Price_Page_faq_list_title"];

        this.faqListItems = [
          {
            title: translations["texts.Package_Tool_FAQ_Item1_Title"],
            content: translations["texts.Package_Tool_FAQ_Item1"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item2_Title"],
            content: translations["texts.Package_Tool_FAQ_Item2"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item3_Title"],
            content: translations["texts.Package_Tool_FAQ_Item3"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item4_Title"],
            content: translations["texts.Package_Tool_FAQ_Item4"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item5_Title"],
            content: translations["texts.Package_Tool_FAQ_Item5"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item6_Title"],
            content: translations["texts.Package_Tool_FAQ_Item6"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item7_Title"],
            content: translations["texts.Package_Tool_FAQ_Item7"],
          },
        ];
      });
  }

  confirmPackage() {
    if (this.packageSelectedToBuy._model?.isEntryPackage || this.packageSelectedToBuy._isExamPackageVersion) {
      this.paymentMethod = PaymentMethod.OneInstallment
    }
    if (this.packageSelectedToBuy.examPackage) {
      const subscribeData: ExamSubscriptionRequestModel = {
        tutorCategory: this.selectedTutorLevel.toString(),
        installmentOption: InstallmentOptionEnum.single,
        paymentOption: this.paymentMethod,
      };
      this.packagesFlowService.subscribeToExamPackage(subscribeData).subscribe({
        next: (response) => {
          this.handleResponse(response.paymentIntent, response.userPackage);
        },
      });
    } else {
      const subscribeData: PackageSubscriptionRequestModel = {
        packageId: this.packageSelectedToBuy.packageId,
        improvementPurpose: "",
        durationMonths: this.packageSelectedToBuy.durationMonths,
        lessonsPerWeek: this.packageSelectedToBuy.lessonsPerWeek.toString(),
        tutorCategory: this.selectedTutorLevel.toString(),
        installmentOption: this.packageSelectedToBuy.installmentOption,
        paymentOption: this.paymentMethod,
      };
      this.packagesFlowService.subscribeToPackage(subscribeData).subscribe({
        next: (response) => {
          this.handleResponse(response.paymentIntent, response.userPackage);
        },
      });
    }
  }

  private handleResponse(
    paymentIntent: StripePaymentIntent,
    userPackage: PackageRecommendationModel
  ) {
    if (paymentIntent.url) {
      this.saveInfoForRedirect(userPackage, paymentIntent);
      this.goToStripe(paymentIntent.url);
    } else {
      this.openSubscribedDialog(userPackage.price);
      this.onClose.emit();
    }
  }

  private saveInfoForRedirect(userPackage: PackageRecommendationModel, paymentIntent: StripePaymentIntent) {
    const paymentInfoForRedirect = {
      redirectUrl: location.pathname + location.search,
      userPackage: userPackage,
      paymentIntent: paymentIntent,
      packageCards: this.packageCards,
      installmentOption: this.packageSelectedToBuy.installmentOption,
      paymentMethod: this.paymentMethod,
      bookingDetails: this.bookingDetailsAfterPayment,
      recurrentBookingDetails: this.bookingRecurrentDetailsAfterPayment
    } as PaymentInfoForForRedirect
    this.packagesFlowService.saveInfoInLocalStorageForRedirect(paymentInfoForRedirect);
  }

  private goToStripe(link) {
    location.href = link;
  }

  private openSubscribedDialog(price: number) {
    this.utilityService.trackEvent("package", "packageBought");
    const dialog = this.popupService.openResponsiveDialog(SubscribedDialogComponent);
    dialog.componentInstance.canNavigateToChat = true;
    dialog.componentInstance.showConfirmButton = true;
    this.packagesFlowService
      .getUserPackages(+this.authService.userDetails.id)
      .subscribe();
  }
}